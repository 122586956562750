import { Component } from '@angular/core';
import {WindowService} from './service/window.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    constructor(
        private windowService: WindowService
    ) {
    }

    public onResize(): void {
        this.windowService.setInnerWidth();
    }
}
