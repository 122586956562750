import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TrainingPlanService {
    private sTrainingPlan: BehaviorSubject<any>;
    private sTrainingPlanLoading: BehaviorSubject<boolean>;

    constructor(
        private http: HttpClient
    ) {
        this.sTrainingPlan = new BehaviorSubject<any>(null);
        this.sTrainingPlanLoading = new BehaviorSubject<boolean>(false);
    }

    get trainingPlan(): Observable<any> {
        return this.sTrainingPlan.asObservable();
    }

    get trainingPlanLoading(): Observable<boolean> {
        return this.sTrainingPlanLoading.asObservable();
    }

    public loadTrainingPlan(): void {
        this.sTrainingPlanLoading.next(true);
        let result: any;
        this.getTrainingPlan().subscribe(
            (res) => result = res,
            (err) => console.error(err),
            () => {
                if (result) {
                    this.sTrainingPlan.next(result);
                }
                this.sTrainingPlanLoading.next(false);
            }
        );
    }

    private getTrainingPlan(): Observable<any> {
        return this.http.get('./assets/training-plan.json');
    }
}
