import {Component, Input, OnInit} from '@angular/core';
import {ObjectHandler} from '../../handler/ObjectHandler';
import {WindowService} from '../../service/window.service';

@Component({
    selector: 'app-training-plan-item',
    templateUrl: './training-plan-item.component.html',
    styleUrls: ['./training-plan-item.component.css']
})
export class TrainingPlanItemComponent implements OnInit {
    @Input() public time: any;

    public innerWidth: number;

    constructor(
        private windowService: WindowService
    ) {
        this.windowService.innerWidth.subscribe((innerWidth) => {
            this.innerWidth = innerWidth;
        });
    }

    public ngOnInit(): void {
    }

    public isEmpty(): boolean {
        return ObjectHandler.isEmpty(this.time);
    }
}
