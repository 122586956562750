<ng-container *ngIf="trainingPlanLoading">
    <div class="row justify-content-center">
        <div class="col-auto">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!trainingPlanLoading">
    <div class="row justify-content-center">
        <div class="col-xs-12 col-xl" *ngFor="let item of trainingPlan">
            <app-training-plan-day
                [day]="item"
            ></app-training-plan-day>
        </div>
    </div>
</ng-container>
