export class ObjectHandler {
    public static isEmpty(object: any): boolean {
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
}
