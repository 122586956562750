import {Component, Input, OnInit} from '@angular/core';
import {WindowService} from '../../service/window.service';

@Component({
    selector: 'app-training-plan-day',
    templateUrl: './training-plan-day.component.html',
    styleUrls: ['./training-plan-day.component.css']
})
export class TrainingPlanDayComponent implements OnInit {
    @Input() public day: any;

    public innerWidth: number;

    constructor(
        private windowService: WindowService
    ) {
        this.windowService.innerWidth.subscribe((innerWidth) => {
            this.innerWidth = innerWidth;
        });
    }

    public ngOnInit(): void {
    }
}
