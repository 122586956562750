 <div class="row">
    <div class="col text-center w-150px">
        <h5 [class.mb-0]="innerWidth < 1200">
            {{day.name}}
        </h5>
        <hr class="mt-1" *ngIf="innerWidth < 1200" />
    </div>
</div>

<div class="row" *ngIf="innerWidth >= 1200">
    <div class="mt-3"></div>
</div>

<div class="row" *ngFor="let time of day.times">
    <div class="col">
        <app-training-plan-item
            [time]="time"
        ></app-training-plan-item>
    </div>
</div>

 <div class="row" *ngIf="innerWidth < 1200">
     <div class="mt-5"></div>
 </div>
