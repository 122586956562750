import { Component, OnInit } from '@angular/core';
import {TrainingPlanService} from '../../service/training-plan.service';

@Component({
    selector: 'app-training-plan',
    templateUrl: './training-plan.component.html',
    styleUrls: ['./training-plan.component.css']
})
export class TrainingPlanComponent implements OnInit {
    public trainingPlan: any;
    public trainingPlanLoading: boolean;

    constructor(
        private trainingPlanService: TrainingPlanService
    ) {
        this.trainingPlanService.trainingPlan.subscribe((trainingPlan) => {
            this.trainingPlan = trainingPlan;
        });
        this.trainingPlanService.trainingPlanLoading.subscribe((trainingPlanLoading) => {
            this.trainingPlanLoading = trainingPlanLoading;
        });
    }

    public ngOnInit(): void {
        this.trainingPlanService.loadTrainingPlan();
    }

}
