import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import { HeaderComponent } from './container/header/header.component';
import { TrainingPlanComponent } from './container/training-plan/training-plan.component';
import { TrainingPlanItemComponent } from './component/training-plan-item/training-plan-item.component';
import {MatCardModule} from '@angular/material/card';
import { TrainingPlanDayComponent } from './component/training-plan-day/training-plan-day.component';
import {HttpClientModule} from '@angular/common/http';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import { FooterComponent } from './container/footer/footer.component';
import { ImprintComponent } from './container/imprint/imprint.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        TrainingPlanComponent,
        TrainingPlanItemComponent,
        TrainingPlanDayComponent,
        FooterComponent,
        ImprintComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatButtonModule,
        MatCardModule,
        MatExpansionModule,
        MatIconModule,
        MatProgressSpinnerModule
    ],
    providers: [],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
