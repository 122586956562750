<div class="footer-container">
    <div class="row justify-content-center">
        <div class="col-auto">
            <div class="row">
                <div class="col">
                    Adresse:<br />
                    Frohnkamp 9<br />
                    40789 Monheim
                </div>
            </div>
            <div class="row">
                <div class="mt-3"></div>
            </div>
            <div class="row">
                <div class="col">
                    Kontakt:<br />
                    Harry Vones<br />
                    info [at] sportschule-monheim.de<br />
                    0151 25232145
                </div>
            </div>
        </div>
    </div>
</div>
