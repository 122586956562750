<div class="container" (window:resize)="onResize()">
    <div class="row">
        <div class="mt-3"></div>
    </div>
    <div class="row">
        <div class="col">
            <app-header></app-header>
        </div>
    </div>
    <div class="row">
        <div class="mt-5"></div>
    </div>
    <div class="row">
        <div class="col">
            <app-training-plan></app-training-plan>
        </div>
    </div>
    <div class="row">
        <div class="mt-5"></div>
    </div>
    <div class="row">
        <div class="col">
            <app-footer></app-footer>
        </div>
    </div>
    <div class="row">
        <div class="mt-5"></div>
    </div>
    <div class="row">
        <div class="mt-5"></div>
    </div>
    <div class="row">
        <div class="col">
            <app-imprint></app-imprint>
        </div>
    </div>
    <div class="row">
        <div class="mt-5"></div>
    </div>
</div>
