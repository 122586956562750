<div class="row">
    <div class="col">
        <div class="space" *ngIf="isEmpty() && innerWidth >= 1200"></div>
        <mat-card *ngIf="!isEmpty()">
            <div class="start-time">
                {{time.start}}
            </div>
            <div class="description">
                {{time.description}}
            </div>
            <div class="end-time">
                {{time.end}}
            </div>
        </mat-card>
    </div>
</div>

<div class="row" *ngIf="!isEmpty() || innerWidth >= 1200">
    <div class="mt-3"></div>
</div>
