import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WindowService {
    private sInnerWidth: BehaviorSubject<number>;
    private sInnerHeight: BehaviorSubject<number>;

    constructor() {
        this.sInnerWidth = new BehaviorSubject<number>(window.innerWidth);
        this.sInnerHeight = new BehaviorSubject<number>(window.innerHeight);
    }

    get innerWidth(): Observable<number> {
        return this.sInnerWidth.asObservable();
    }

    get innerHeight(): Observable<number> {
        return this.sInnerHeight.asObservable();
    }

    public setInnerWidth(): void {
        this.sInnerWidth.next(window.innerWidth);
    }

    public setInnerHeight(): void {
        this.sInnerHeight.next(window.innerHeight);
    }

}
